import React from 'react'
import {Link} from 'gatsby'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import nextimg from '../images/next.svg'

import styles from './linkArray.module.css'

const LinkArray = ({links}) => {
    return(
        <div className={styles.linkblocks}>
            {links.map( props => (
                <div className={styles.link}>
                    <Link to={props.url}><div className={styles.linktitle}><p>{props.texto}</p><img src={nextimg} alt="Seta vermelha" /></div>
                        <div className={styles.mainimg}>
                            <img
                                src={imageUrlFor(buildImageObj(props.image))
                                .width(1920)
                                .height(Math.floor((9 / 16) * 1920))
                                .fit('crop')
                                .auto('format')
                                .url()}
                                alt={props.image.alt}
                            />
                        </div>
                    </Link>
                </div>
            ))}
        </div>

    )
}

export default LinkArray



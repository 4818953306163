import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import styles from './social.module.css'

function Social (props) {
    return(
        <div className={styles.social}>
            {props && <div className={styles.textoSocial}><PortableText blocks={props.body} /></div>}
            
            <div className={styles.socialLinks}>
                {props.photos && props.photos.map( fotos => (
                    <a href={fotos.url} target="_blank" rel="noopener noreferrer">
                        <img
                            src={imageUrlFor(buildImageObj(fotos.image))
                            .width(1920)
                            .height(Math.floor((9 / 16) * 1920))
                            .fit('crop')
                            .auto('format')
                            .url()}
                            alt={fotos.image.alt}
                        />
                    </a>
                ))
                }
            </div>
        </div>

    )
}

export default Social
import React from 'react'
import {Link} from 'gatsby'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'

import styles from './news.module.css'

function News (props) {
    return(
        <div>
            {props.image.asset && <div className={styles.news}>
                <div className={styles.top}>
                <h1>{props.texto}</h1>
                <Link to={props.url}>
                    <button className={styles.button}><p>Conheça a história</p></button>
                </Link>
                </div>
                <div className={styles.bot}>
                <img
                    src={imageUrlFor(buildImageObj(props.image))
                    .width(1920)
                    .height(Math.floor((9 / 16) * 1920))
                    .fit('crop')
                    .auto('format')
                    .url()}
                    alt={props.image.alt}
                />
            </div>
        </div>}
        </div>

    )
}

export default News
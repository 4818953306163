import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import styles from './txtImg.module.css'

function TxtImg (props) {
    return(
        <div>
            {props.image.asset && <div className={styles.txtimg}>
            <img
            src={imageUrlFor(buildImageObj(props.image))
                .width(1920)
                .height(Math.floor((9 / 16) * 1920))
                .fit('crop')
                .auto('format')
                .url()}
            alt={props.image.alt}
            />
            <PortableText blocks={props.body} />
        </div>}
      </div>

    )
}

export default TxtImg
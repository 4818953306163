import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

import styles from "./imgBot.module.css"

const ImgBot = () => {
    return(
        <div className={styles.botimg}>  
            <StaticImage
                src="../images/imgnav.png"
                alt="As Ganhadeiras de Itapuã"
                placeholder="blurred"
            />
        </div>
    )
}

export default ImgBot
import React from 'react'
import {Link} from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"


import Videolagoa from "../video/videolagoa.webm"
import Videolagoamp4 from "../video/videolagoamp4.mp4"

import styles from "./video.module.css"

const Video = () => {
    return(
        <div className={styles.portal}>
            
            <div className={styles.textdiv}>
                <div className={styles.text}>
                    <p>Viva essa experiência</p>
                    <Link to="/recepcao"><button>Entrar</button></Link>
                </div>
            </div>

            <div className={styles.imgmobile}>
                <StaticImage
                    src="../images/portalmobile.png"
                    alt="Portal mobile"
                    placeholder="blurred"
                />
            </div>

            <div className={styles.imgtablet}>
                <StaticImage
                    src="../images/portaltablet.png"
                    alt="Portal tablet"
                    placeholder="blurred"
                />
            </div>

            <div className={styles.imgdesk}>
                <StaticImage
                    src="../images/portaldesktop.png"
                    alt="Portal desktop"
                    placeholder="blurred"
                />
            </div>
            
            <div className={styles.video}>
                <video autoPlay loop muted playsInline>
                    <source src={Videolagoa} type="video/webm" />
                    <source src={Videolagoamp4} type="video/mp4" />
                    Seu navegador não suporta videos.
                </video>
            </div>
            
        </div>
    )
}

export default Video
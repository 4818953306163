import React from 'react'
import {buildImageObj} from '../lib/helpers'
import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'

import styles from './imgTxt.module.css'

function ImgTxt (props) {
    return(
        <div>
            {props.image.asset && <div className={styles.imgtxt}>
                <img
                src={imageUrlFor(buildImageObj(props.image))
                    .width(1920)
                    .height(Math.floor((9 / 16) * 1920))
                    .fit('crop')
                    .auto('format')
                    .url()}
                alt={props.image.alt}
                />
                <PortableText blocks={props.body} />
            </div>}
        </div>
    )
}

export default ImgTxt